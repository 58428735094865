import clsx from 'clsx'
import React, { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Icon from 'src/components/icons/icon'
import { useIsDemoAppMode } from 'src/utils/hooks/use-is-demo-app-mode'

import styles from './button.module.css'

export type ButtonProps = {
  /** Custom CSS Classes */
  className?: string
  /** onClick handler */
  onClick?: MouseEventHandler
  /** Text on the button */
  label?: string
  /** Element inside the button */
  children?: React.ReactNode
  /** Determines the style of the button * */
  kind?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'link' | 'toggle' | 'primaryGeneric' | 'embedded'
  /** Custom style prop */
  style?: Record<string, any>
  /** if href provided to button, button will be a link */
  href?: string
  /** target of href */
  target?: string
  /** name of icon */
  icon?: string | ReactElement | ReactNode | Array<string>
  /** Size of Icon. Takes number or pixel value. Example size={30} */
  iconSize?: string | number
  /** Cg adustom Icon props */
  iconProps?: {
    className?: string
    size?: number
    icon?: string
    isSpinning?: boolean
  }
  /** Custom Inner content props */
  contentProps?: Record<string, any>
  /** Dark button theme */
  dark?: boolean
  /** Determines the size of the button */
  size?: 'small' | 'medium' | 'large'
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  to?: string | number
  form?: string
  id?: string
  name?: string
}

/**
 * Protip: Use `kind` prop to set styles of button
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      label,
      children,
      className,
      kind: initialKind = 'primary',
      style,
      href,
      to,
      target,
      icon,
      iconSize = 20,
      iconProps = {},
      contentProps = {},
      dark,
      size = 'small',
      type = 'button', // Default is 'submit' otherwise
      ...rest
    },
    ref,
  ) => {
    // If in demo app mode, use generic blue primary buttons
    let kind = initialKind
    const isDemoAppMode = useIsDemoAppMode()
    if (isDemoAppMode && kind === 'primary') {
      kind = 'primaryGeneric'
    }

    const text = label || children

    const classes = clsx(
      className,
      styles.button,
      styles[kind],
      dark ? styles.dark : '',
      styles[size],
      icon && styles.hasIcon,
      rest.disabled && styles.disabled,
    )

    let iconRender
    if (icon) {
      if (typeof icon === 'string') {
        // Icon is string use <use> icon
        const restProps = {
          ...iconProps,
          size: iconProps.size || iconSize,
          name: icon || (iconProps.icon as string),
          className: clsx(styles.icon, iconProps.className, text && styles.iconMargin),
        }
        iconRender = <Icon {...restProps} />
      } else if (icon instanceof Array) {
        // Buttons with more than one icon
        const restProps = {
          ...iconProps,
          size: iconProps.size || iconSize,
          className: clsx(styles.icon, iconProps.className),
        }
        iconRender = icon.map((ele, i) => {
          return <Icon key={i} name={ele} {...restProps} />
        })
      } else {
        // Components
        iconRender = <div style={{ marginRight: text ? 10 : 0, display: 'flex' }}>{icon}</div>
      }
    }

    // Make link if href supplied
    if (href) {
      return (
        // @ts-ignore ignore problem with type
        <a className={classes} href={href} target={target} onClick={onClick} style={style} ref={ref} {...rest}>
          {iconRender}
          <span>{text}</span>
        </a>
      )
    }
    if (to) {
      return (
        // "to" does take string | number but the type definition is wrong
        // @ts-ignore ignore problem with type
        <Link className={classes} to={to as string} target={target} onClick={onClick} style={style} ref={ref} {...rest}>
          {iconRender}
          <span className={styles.buttonText}>{text}</span>
        </Link>
      )
    }

    return (
      // @ts-ignore ignore problem with type
      <button className={classes} onClick={onClick} style={style} type={type} {...rest} ref={ref}>
        {iconRender}
        <span className={styles.buttonText} {...contentProps}>
          {text}
        </span>
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
